import './video-slider.scss';

// import { Slider } from './../../components/slider/slider';
import { A11y, Navigation } from 'swiper';

class VideoSlider {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-videoslider'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$videoSlider = $element;
        this.$videoSliderSlides = this.$videoSlider.querySelectorAll('[' + this.settings.initAttr + '="slide"]');
        this.videoSlider = null;

        this.resizeHandler = window.resizeHandler;
        this.sliderActive = false;

        if (this.$videoSliderSlides.length > 1) {
            import(
                /* webpackChunkName: "slider/slider.js" */
                './../../components/slider/slider').then(({ Slider }) => {
                this.Slider = Slider;
                this.initialize();
            });
        }
        // this.initialize();
    }

    initialize () {
        this.checkSize();
        this.resizeHandler.customFunctions.push(() => {
            this.checkSize();
        });
    }

    checkSize () {
        if (window.innerWidth < 1440 && this.$videoSliderSlides.length > 1) {
            if (this.sliderActive === false) {
                this.initSlider();
            }
        } else {
            if (this.sliderActive === true) {
                this.destroySlider();
                this.sliderActive = false;
            }
        }
    }

    initSlider () {
        this.videoSlider = new this.Slider(this.$videoSlider, {
            modules: [A11y, Navigation],
            speed: 500,
            slidesPerView: 'auto',
            centeredSlides: true,
            centeredSlidesBounds: true,
            initAttr: this.settings.initAttr,
            breakpoints: {
                1024: {
                    centeredSlides: false,
                    centeredSlidesBounds: false
                }
            }
        });

        this.sliderActive = true;
    }

    destroySlider () {
        if (typeof this.videoSlider !== 'undefined' && this.videoSlider !== null) {
            this.videoSlider.destroy();
        }
    }
}

export { VideoSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$videoSlider = $context.querySelectorAll('[data-videoslider="root"]');
        for (let i = 0; i < $$videoSlider.length; i++) {
            const $videoSlider = $$videoSlider[i];

            const videoSliderAPI = new VideoSlider($videoSlider);
            $videoSlider.API = videoSliderAPI;
        }
    }
});
